.menu__item {
	height: 10em;
	width: 100%;
	padding-right: 1em;
	box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	position: relative;
	display: flex;
	align-items: center;
	align-self: center;
	margin: 2.5em 0;
	transition: 0.2s all ease;
	color: #000;
	border-top-left-radius: 5em;
	border-bottom-left-radius: 5em;
	cursor: pointer;
}

.menu__item:hover {
	transform: scale(1.1);
}

.menu__item .menu__item__circle {
	position: absolute;
	height: 12em;
	width: 12em;
	border: 1em solid #fff;
	border-radius: 50%;
	left: 0;
	background: #fff;
	box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.05);
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

.menu__item__circle__img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.menu__item__content {
	padding-left: 14em;
}
.menu__item__content p,
.menu__item__content h3 {
	cursor: pointer;
}
