.contenedor__ajuste{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4rem;
}
.contenedor__ajuste h1{
    margin-top: 0.7rem;
}
.ajuste__acciones button{
    height: 3em;
    min-width: 8em;
    margin: 0 1em;
    border: none;
    outline: none;
    border-radius: 5px;
    color: #fff;
    font-weight: 500;
    background: #00c6ff;
    background: linear-gradient(to right, #0072ff, #00c6ff);
}