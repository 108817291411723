.header {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 3fr 1fr;
	grid-template-rows: 8em;
	grid-template-areas: 'logo input shopping-cart';
	align-items: center;
	padding: 0 2em;
	position: sticky;
	top: 0;
	background: rgb(255, 255, 255, 0.8);
	-webkit-backdrop-filter: saturate(180%) blur(20px);
	backdrop-filter: saturate(180%) blur(20px);
	z-index: 10;
}

.main-container__title {
	display: flex;
	align-items: center;
	justify-content: center;
}

.main-container__title__h1 {
	
}

.header__button {
	grid-area: shopping-cart;
	background: none;
	height: 8em;
	outline: none;
	border: none;
	position: relative;
	transition: 0.2s all ease;
	display: flex;
	align-items: center;
	justify-content: center;
	justify-self: flex-end;
}

.header__button:hover {
	transform: scale(1.1);
}

.header__button__img {
	height: 60%;
}

.header__button__count {
	position: absolute;
	right: 0.2em;
	top: 1.7em;
	height: 1.5em;
	width: 1.5em;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background: rgb(255, 106, 106);
	font-weight: bold;
	color: #fff;
}

.header__logo {
	width: 10em;
	grid-area: logo;
}

.header__input {
	grid-area: input;
	background: rgb(233, 233, 233);
	padding: 0 2em;
	outline: none;
	border: none;
	height: 3em;
	border-radius: 25px;
	width: 50%;
	align-self: center;
	justify-self: center;
	text-align: center;
}

.menu {
	margin-top: 2em;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(25em, 1fr));
	grid-column-gap: 2em;
	padding: 0 3em;
}

.menu__section {
	padding: 2em;
	display: flex;
	flex-direction: column;
}

.fullscreen-loader {
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.fullscreen-loader__img {
	width: 50%;
}

.no-food {
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 5em;
}

.carrito-row {
	width: 100%;
	display: grid;
	grid-template-columns: minmax(auto, 1fr) repeat(3, 1fr);
	grid-column-gap: 1em;
	grid-template-rows: 2em;
	align-items: center;
}

.carrito-row span:nth-child(3),
.carrito-row span:nth-child(2),
.carrito-row h4:nth-child(2),
.carrito-row h4:nth-child(3),
.carrito-row h4:nth-child(4) {
	justify-self: center;
}

.carrito-total {
	width: 100%;
	margin-top: 1em;
	padding-top: 1em;
	border-top: 1px solid rgb(221, 221, 221);
	display: flex;
	justify-content: center;
	align-items: center;
}

.no-products {
	margin: 1em 0;
}

.delete-product-cart {
	background: rgb(255, 143, 143);
	border-radius: 5px;
	font-weight: 500;
	color: #fff;
	padding: 0.7em 2em;
	border: none;
	outline: none;
	justify-self: center;
}

.input-mesa {
	-webkit-appearance: none;
	border: none;
	background: rgb(228, 228, 228);
	border-radius: 5px;
	margin-left: 1em;
	outline: none;
	padding: 1em;
	height: 3em;
	width: 5em;
}

.formulario-pedido {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.observaciones {
	display: flex;
	flex-direction: column;
}

.observaciones__textarea {
	height: 5em;
	resize: none;
	width: 100%;
	margin: 1.5em 0;
	padding: 0 1em;
	padding-top: 1em;
	font-size: 0.8em;
	font-weight: 500;
}

@media screen and (max-width: 1024px) {
	.menu {
		padding: 0 2em;
	}

	.header {
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 8em 8em;
		grid-template-areas:
			'logo shopping-cart'
			'input input';
	}
	.header__input {
		width: 90%;
	}
}
