.user-panel-container {
	width: 100%;
	display: flex;
	justify-content: center;
}

.user-panel {
	width: 60%;
}

.user-panel__header {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 2fr 1fr;
	grid-template-rows: 3em;
	grid-row-gap: 1em;
	grid-template-areas: 'logo menu profile';
	align-items: center;
	height: 6em;
	padding: 1.5em 0;
}

.user-panel__header__list {
	display: flex;
	width: 100%;
	justify-content: space-around;
	grid-area: menu;
	list-style: none;
}

.user-panel__header__link {
	color: #000;
	font-weight: 500;
	display: inline-block;
	margin: 0 2em;
}

.user-panel__header__logo {
	display: inline-block;
	height: 100%;
	grid-area: logo;
	justify-self: flex-start;
}

.user-panel__header__logo--img {
	height: 100%;
}

.user-panel__profile {
	grid-area: profile;
	background: #f8f8f8;
	display: flex;
	align-items: center;
	height: 100%;
	padding: 0 1em;
	border-radius: 25px;
	font-weight: 500;
	position: relative;
	transition: 0.2s all ease;
	justify-self: flex-end;
}

.user-panel__profile__logout {
	color: #ff8282;
	font-size: 0.8em;
	cursor: pointer;
}
.user-panel__profile__logout:hover {
	color: #ff5b5b;
}

.header-active-link {
	color: #44ea7c;
	font-weight: 600;
}

.user-panel__profile__image {
	width: 2em;
	height: 2em;
	border-radius: 50%;
	object-fit: cover;
	margin-right: 1em;
}

.user-panel__profile__text__container {
	display: flex;
	flex-direction: column;
	text-align: center;
}

.user-panel__title {
	margin-top: 2em;
	padding: 1.5em 0;
	border-bottom: 1px solid #383838;
	margin-bottom: 2em;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-areas: 'title button switch';
	justify-content: space-between;
}

.user-panel__title--h1 {
	font-weight: 600;
	grid-area: title;
}

.user-panel__main {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.user-panel__main__link {
	width: 50%;
	display: inline-block;
	margin-bottom: 3em;
}

.main-menu-card {
	width: 100%;
	height: 12em;
	border-radius: 2em;
	color: #fff;
	padding: 2em 3em;
	position: relative;
	transition: 0.2s all ease;
}

.main-menu-card:hover {
	transform: scale(1.1);
	box-shadow: 0px 18px 30px rgba(0, 0, 0, 0.1);
}

.card-1 {
	background: linear-gradient(90deg, #2b84ec 0%, #725be2 100%);
}
.card-2 {
	background: linear-gradient(90deg, #00cdac 0%, #8ddad5 100%);
}

.main-menu-card__arrow-container {
	height: 2em;
	width: 2em;
	border-radius: 10px;
	background: #fff;
	margin-top: 1em;
	display: flex;
	align-items: center;
	justify-content: center;
}

.main-menu-card__arrow-container--img {
	width: 1em;
}

.main-menu-card--img-1 {
	position: absolute;
	bottom: 0;
	right: -2em;
	width: 50%;
}

.main-menu-card--img-2 {
	position: absolute;
	bottom: 0;
	right: -2em;
	width: 50%;
}

.user-panel__title--button {
	background: #44ea7c;
	grid-area: button;
	justify-self: center;
	color: #fff;
	border: none;
	outline: none;
	font-weight: 600;
	padding: 0 2em;
	border-radius: 5px;
	display: flex;
	align-items: center;
	transition: 0.2s all ease;
}

.user-panel__title--button:hover {
	background: #71ffa1;
}

.user-panel__title--button-icon {
	margin-left: 1em;
}

.user-panel__switch_input[type='checkbox'] {
	height: 0;
	width: 0;
	visibility: hidden;
}

.user-panel__switch_label {
	cursor: pointer;
	text-indent: -9999px;
	width: 50px;
	height: 25px;
	background: grey;
	display: block;
	border-radius: 100px;
	position: relative;
}

.user-panel__switch_label:after {
	content: '';
	position: absolute;
	top: 5px;
	left: 5px;
	width: 15px;
	height: 15px;
	background: #fff;
	border-radius: 90px;
	transition: 0.3s;
}

.user-panel__switch_input:checked + label {
	background: #44ea7c;
}

.user-panel__switch_input:checked + label:after {
	left: calc(100% - 5px);
	transform: translateX(-100%);
}

.user-panel__switch_label:active:after {
	width: 25px;
}

.switch-container {
	display: flex;
	justify-self: flex-end;
	grid-area: switch;
	align-items: center;
}

.switch-container__text {
	font-weight: 500;
	display: inline-block;
	margin-right: 1em;
}

@media screen and (max-width: 1024px) {
	.user-panel {
		width: 80%;
	}
	.user-panel__header {
		grid-template-columns: 40% 60%;
		grid-template-rows: 3em 3em;
		grid-template-areas:
			'logo profile'
			'menu menu';
	}
}

@media screen and (max-width: 768px) {
	.user-panel {
		width: 90%;
	}
	.user-panel__main__link {
		width: 80%;
	}

	.user-panel__title--button {
		height: 3em;
		margin-top: 2em;
	}
	.user-panel__title {
		grid-template-columns: 1fr 1fr;
		grid-template-rows: auto auto;
		grid-template-areas:
			'title switch'
			'button button';
	}
}
