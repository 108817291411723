.landing-container {
	background: #000;
	width: 100%;
	display: grid;
	grid-template-rows: auto 5fr 2fr;
	height: 100vh;
}

.header-landing {
	height: 5em;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1.5em 25%;
}

.header-landing__logo {
	height: 100%;
	display: inline-block;
}

.header-landing__logo--img {
	height: 100%;
}

.landing__button {
	color: #fff;
	background: #008f39;
	border-radius: 25px;
	padding: 0.6em 1.5em;
	font-weight: 600;
	transition: all 0.3s ease;
}
.landing__button:hover {
	background: #04481f;
	
}

.landing__button--header {
	height: 100%;
}

.landing__button__main {
	height: 3em;
	margin-top: 2.5em;
	display: flex;
	align-items: center;
	justify-content: space-between;
	z-index: 10;
	min-width: 15em;
	margin: 0 1em;
}

.landing__button__main-demo {
	color: black;
	background: #fff;
	border-radius: 25px;

	padding: 0.6em 1.5em;
	font-weight: 600;
}

.landing__button--img {
	margin-left: 0.5em;
}

.header-landing__login {
	display: inline-block;
	margin-right: 1em;
	color: #44ea7c;
	font-weight: 600;
}

.landing-container__main {
	color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 10;
}

.landing-container__main--h1 {
	font-size: 6em;
	text-align: center;
	line-height: 0.9em;
}

.landing-container__main--p {
	font-size: 2em;
	color: #b5b5b5;
	text-align: center;
}

.landing-container__illustration {
	position: fixed;
	width: 50em;
	opacity: 0.3;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 0);
}

.fadeIn {
	-webkit-animation-duration: 2s;
	animation-duration: 2s;
	-webkit-animation-name: fadeIn;
	animation-name: fadeIn;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.landing-container__button-hero {
	display: flex;
}

@media screen and (max-width: 768px) {
	.landing-container__main--h1 {
		font-size: 4em;
	}
	.landing__button--header {
		display: none;
	}
	.header-landing {
		padding: 2em 12%;
		height: 6em;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
